<template>
  <header class="header-global">
    <base-nav class="navbar-main nav-transparent" id="navbar-main" transparent type effect="light" expand :color-on-scroll="colorOnScroll">
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img v-lazy="'img/brand/nifty-logo.svg'" alt="logo" />
      </router-link>

      <div slot="content-header" slot-scope="{closeMenu}">
        <close-button @click="closeMenu" class="close-button"></close-button>
        <div class="col-12 collapse-brand text-center">
          <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
            <img v-lazy="'img/brand/nifty-logo.svg'" alt="logo" />
          </router-link>
        </div>
      </div>
      <div class="nav-holder" slot-scope="{closeMenu}">
        <scrollactive active-class="active" :offset="80">
          <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
            <li class="nav-item" @click="closeMenu">
              <router-link
                class="nav-link nav-link-icon scrollactive-item"
                to="#about"
                data-toggle="tooltip"
                title="About"
              >
                <i class="ni ni-planet"></i>
                <span class="nav-link-inner--text">About Us</span>
              </router-link>
            </li>
            <li class="nav-item" @click="closeMenu">
              <router-link
                class="nav-link nav-link-icon scrollactive-item"
                to="#services"
                data-toggle="tooltip"
                title="Services"
              >
                <i class="ni ni-settings"></i>
                <span class="nav-link-inner--text">Services</span>
              </router-link>
            </li>
            <li class="nav-item" @click="closeMenu">
              <router-link
                class="nav-link nav-link-icon scrollactive-item"
                to="#projects"
                data-toggle="tooltip"
                title="Projects"
              >
                <i class="ni ni-ruler-pencil"></i>
                <span class="nav-link-inner--text">Projects</span>
              </router-link>
            </li>
            <li class="nav-item" @click="closeMenu">
              <router-link
                class="nav-link nav-link-icon scrollactive-item"
                to="#contact"
                data-toggle="tooltip"
                title="Contact"
              >
                <i class="ni ni-spaceship"></i>
                <span class="nav-link-inner--text">Contact Us</span>
              </router-link>
            </li>
          </ul>
        </scrollactive>  
        <ul class="navbar-nav align-items-lg-center ml-lg-auto">
          <li class="nav-item" @click="closeMenu">
            <a
              class="nav-link nav-link-icon"
              href="https://www.facebook.com/niftystack.io/"
              target="_blank"
              rel="noopener"
              data-toggle="tooltip"
              title="Like us on Facebook"
            >
              <i class="fa fa-facebook-square"></i>
              <span class="nav-link-inner--text d-lg-none">Facebook</span>
            </a>
          </li>
          <li class="nav-item" @click="closeMenu">
            <a
              class="nav-link nav-link-icon"
              href="https://www.instagram.com/niftystack.io"
              target="_blank"
              rel="noopener"
              data-toggle="tooltip"
              title="Follow us on Instagram"
            >
              <i class="fa fa-instagram"></i>
              <span class="nav-link-inner--text d-lg-none">Instagram</span>
            </a>
          </li>
          <li class="nav-item" @click="closeMenu">
            <a
              class="nav-link nav-link-icon"
              href="https://twitter.com/niftystack"
              target="_blank"
              rel="noopener"
              data-toggle="tooltip"
              title="Follow us on Twitter"
            >
              <i class="fa fa-twitter-square"></i>
              <span class="nav-link-inner--text d-lg-none">Twitter</span>
            </a>
          </li>
          <li class="nav-item" @click="closeMenu">
            <a
              class="nav-link nav-link-icon"
              href="https://github.com/niftyStack"
              target="_blank"
              rel="noopener"
              data-toggle="tooltip"
              title="Star us on Github"
            >
              <i class="fa fa-github"></i>
              <span class="nav-link-inner--text d-lg-none">Github</span>
            </a>
          </li>
        </ul>
      </div>  
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";

let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();
      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  },
  props: {
    type: {
      type: String,
      default: "white",
    },
    colorOnScroll: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      extraNavClasses: "",
      toggledClass: false,
      activeNav: null
    };
  },
  methods: {
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("navbar-main");
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `nav-${this.type}`;
        navbarColor.classList.remove("nav-transparent");
        navbarColor.classList.add("nav-solid");
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("nav-transparent");
          navbarColor.classList.remove("nav-solid");
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    },
    scrollToElement() {
      let element_id = document.getElementById("downloadSection");
      if (element_id) {
        element_id.scrollIntoView({ block: "end", behavior: "smooth" });
      }
    }
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
    this.activeNav = this.$router.currentRoute.name;
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  watch: {
    $route() {
      this.activeNav = this.$router.currentRoute.name;
    }
  }
};
</script>
<style lang="scss" scoped>
  .header-global {
    position: fixed;
    width: 100%;
    z-index: 1001;

    nav {
      transition: all 0.1s ease;

      .navbar-brand img {
        transition: all 0.2s ease;
      }

      &.nav-transparent {
        background: transparent;

        .navbar-brand img {
          height: 80px;
        }
      }

      &.nav-solid {
        background: #222231;

        .navbar-brand img {
          height: 50px;
        }
      }

      .nav-link.active {
        color: #288efc;
      }
    }
  }

  @media(max-width: 991px) {
    .navbar-brand img {
      height: 50px !important;
    }
  }
</style>
