<template>
  <component :is="tag" class="image-container">
    <canvas class="canvas" width="1080" height="1080" :id="canvasId"></canvas>
    <div class="loading-screen" :id="loadingScreenId">Loading...</div>
  </component>  
</template>
<script>
  export default {
    name: "test-canvas",
    components: {},
      props: {
      canvasId: {
        type: String,
        default: "canvas",
      },
      loadingScreenId: {
        type: String,
        default: "loading",
      },
      layerData: {
        type: Array,
        default: null
      },
      tag: {
        type: String,
        default: "div"
      },
    },
    data() {
      return {
        canvas: null,
        loading_screen: null,
        context: null,
        loaded: false,
        moving: false,
        window: null,
        pointer_initial: {
          x: 0,
          y: 0
        },
        pointer: {
          x: 0,
          y: 0
        },
        motion_initial: {
          x: null,
          y: null
        },
        motion: {
          x: 0,
          y: 0
        },
      };
    },
    methods: {
      drawCanvas() {
        let $this = this;
        $this.context.clearRect(0, 0, $this.canvas.width, $this.canvas.height);
        
        TWEEN.update();
      
        let rotate_x = ($this.pointer.y * -0.15) + ($this.motion.y * 1.2);
        let rotate_y = ($this.pointer.x * 0.15) + ($this.motion.x * 1.2);
        
        $this.canvas.style.transform = "rotateX(" + rotate_x + "deg) rotateY(" + rotate_y + "deg)";

        $this.layerData.forEach(function(layer, index) {
          let newPosition = $this.getOffset(layer);
          if (layer.blend) {
            $this.context.globalCompositeOperation = layer.blend;
          } else {
            $this.context.globalCompositeOperation = 'normal';
          }
          $this.context.globalAlpha = layer.opacity;
          $this.context.drawImage(layer.image, layer.position.x + newPosition.x, layer.position.y + newPosition.y);
        });
        
        requestAnimationFrame($this.drawCanvas);
      },
      getOffset(layer) {
        let $this = this;
        let touch_multiplier = 0.3;
        let touch_offset_x = $this.pointer.x * layer.z_index * touch_multiplier;
        let touch_offset_y = $this.pointer.y * layer.z_index * touch_multiplier;
        let motion_multiplier = 2.5;
        let motion_offset_x = $this.motion.x * layer.z_index * motion_multiplier;
        let motion_offset_y = $this.motion.y * layer.z_index * motion_multiplier;
        let offset = {
          x: touch_offset_x + motion_offset_x,
          y: touch_offset_y + motion_offset_y
        };

        return offset;
      },
      pointerStart(event) {
        let $this = this;
        $this.moving = true;
        if (event.type === 'touchstart') {
          $this.pointer_initial.x = event.touches[0].clientX;
          $this.pointer_initial.y = event.touches[0].clientY;
        } else if (event.type === 'mousedown') {
          $this.pointer_initial.x = event.clientX;
          $this.pointer_initial.y = event.clientY;
        }
      },
      pointerMove(event) {
        let $this = this;
        if ($this.moving === true) {
          let current_x = 0;
          let current_y = 0;
          if (event.type === 'touchmove') {
            current_x = event.touches[0].clientX;
            current_y = event.touches[0].clientY;
          } else if (event.type === 'mousemove') {
            current_x = event.clientX;
            current_y = event.clientY;
          }
          $this.pointer.x = current_x - $this.pointer_initial.x;
          $this.pointer.y = current_y - $this.pointer_initial.y; 
        }
      },
      endGesture() {
        let $this = this;
        $this.moving = false;
        TWEEN.removeAll();
        let pointer_tween = new TWEEN.Tween($this.pointer).to({x: 0, y: 0}, 300).easing(TWEEN.Easing.Back.Out).start();
      },
      checkLoading() {
        let $this = this
        let layerLength = $this.layerData.length
        let loadCounter = 0
        $this.layerData.forEach(function(layer, index) {
          layer.image = new Image();
          layer.image.onload = function() {
            loadCounter = loadCounter + 1;
            if (loadCounter >= layerLength) {
              $this.loading_screen.classList.add('hidden');
              requestAnimationFrame($this.drawCanvas);
            }
          };
          layer.image.src = layer.src;
        });
      },
      resetOffset() {
        this.motion_initial.x = 0;
        this.motion_initial.y = 0;
      },
      deviceOrientationHandler(event) {
        let $this = this
        if (!$this.motion_initial.x && !$this.motion_initial.y) {
          $this.motion_initial.x = event.beta;
          $this.motion_initial.y = event.gamma;
        }
        if ($this.window.orientation === 0) {
          // The device is right-side up in portrait orientation
          $this.motion.x = event.gamma - $this.motion_initial.y;
          $this.motion.y = event.beta - $this.motion_initial.x;
        } else if ($this.window.orientation === 90) {
          // The device is in landscape laying on its left side
          $this.motion.x = event.beta - $this.motion_initial.x;
          $this.motion.y = -event.gamma + $this.motion_initial.y;
        } else if ($this.window.orientation === -90) {
          // The device is in landscape laying on its right side
          $this.motion.x = -event.beta + $this.motion_initial.x;
          $this.motion.y = event.gamma - $this.motion_initial.y;
        } else {
          // The device is upside-down in portrait orientation
          $this.motion.x = -event.gamma + $this.motion_initial.y;
          $this.motion.y = -event.beta + $this.motion_initial.x;
        }

        let max_offset = 15;
          
        // Check if magnitude of motion offset along X axis is greater than your max setting
        if (Math.abs($this.motion.x) > max_offset) {
          // Check whether offset is positive or negative, and make sure to keep it that way
          if ($this.motion.x < 0) {
            $this.motion.x = -max_offset;
          } else {
            $this.motion.x = max_offset;
          }
        }
        // Check if magnitude of motion offset along Y axis is greater than your max setting
        if (Math.abs($this.motion.y) > max_offset) {
          // Check whether offset is positive or negative, and make sure to keep it that way
          if ($this.motion.y < 0) {
            $this.motion.y = -max_offset;
          } else {
            $this.motion.y = max_offset;
          }
        }
      }
    },
    mounted() {
      let $this = this;
      $this.canvas = document.getElementById($this.canvasId);
      $this.context = $this.canvas.getContext('2d');
      $this.loading_screen = document.getElementById($this.loadingScreenId);
      $this.window = window;

      $this.canvas.addEventListener('touchstart', $this.pointerStart);
      $this.canvas.addEventListener('mousedown', $this.pointerStart);
      $this.window.addEventListener('mousemove', $this.pointerMove);
      $this.window.addEventListener('touchmove', $this.pointerMove);
      $this.canvas.addEventListener('mouseout', $this.endGesture);
      $this.canvas.addEventListener('touchend', $this.endGesture);

      $this.window.addEventListener('deviceorientation', $this.deviceOrientationHandler, false);
      $this.window.addEventListener('orientationchange', $this.resetOffset, false);

      $this.checkLoading();
    },
  };
</script>
<style lang="scss">
  .image-container {
    display: block;
    height: 100%;
    max-width: 100%;
    position: relative;
    -webkit-perspective: 1500px;
    perspective: 1500px;
    transform-style: preserve-3d;
    transform: translate3d(0, 0, 0);	
  }
  .canvas {
    width: auto;
    max-height: 100%;
    max-width: 100%;
    display: block;
    margin: auto;

    &:hover {
      cursor: pointer;
    }
  }
  .loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    visibility: visible;
    opacity: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 40px;
    transition: 0.25s ease all 0.75s;
    color: #999;
    /* transform: translateZ(50px); */
  }
  .loading-screen.hidden {
    visibility: hidden;
    opacity: 0;
  }
</style>

