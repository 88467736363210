import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './registerServiceWorker';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueScrollactive from 'vue-scrollactive';
import VeeValidate from 'vee-validate';
import VueResource from 'vue-resource';

Vue.use(VueResource);
Vue.use(VeeValidate);
Vue.use(VueScrollactive);
Vue.config.productionTip = false;
Vue.use(Argon);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDx5kr18QnHX5QTySjNrMPDB1qsEkJM6qw',
    libraries: 'places',
  },
})

const fixIdScrolling = {
  watch: {
    $route(to, from) {
      const currentRoute = this.$router.currentRoute;
      const idToScrollTo = currentRoute.hash;
      this.$nextTick(() => {
        if (idToScrollTo && document.querySelector(idToScrollTo)) {
          document.querySelector(idToScrollTo).scrollIntoView({
            block: "start",
            behavior: "smooth"
          });
        }
      });
    },
  },
};

new Vue({
  mixins: [fixIdScrolling],
  created() {
    AOS.init()
  },
  router,
  render: h => h(App)
}).$mount("#app");
