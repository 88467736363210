<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0">
        <div class="shape shape-primary shape-skew">
          <div class="pulsating-circle"></div>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-4">
                <h1 class="text-white width-500">
                  Building things the
                  nifty way.
                </h1>
                <p
                  class="lead text-white"
                >With our experience and expertise in design and development, we can help you scale your business and achieve your goals.</p>
                <div class="btn-wrapper">
                  <router-link to="#projects">
                    <base-button
                      tag="button"
                      class="mb-3 mb-sm-0 btn-default"
                      type="info"
                      icon="fa fa-code"
                    >View Our Projects</base-button>
                  </router-link>
                </div>
              </div>

              <div class="col-lg-8 section-image-header">
                <img
                  v-lazy="'img/theme/header-image.svg'"
                  alt="header-image"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="linear"
                  data-aos-once="true"
                />
                <img
                  v-lazy="'img/theme/chart-card.svg'"
                  alt="chart-image"
                  class="floating-images floating chart"
                  data-aos="zoom-in"
                  data-aos-delay="1500"
                  data-aos-duration="500"
                  data-aos-easing="linear"
                  data-aos-once="true"
                />
                <img
                  v-lazy="'img/theme/plane-card.svg'"
                  alt="plane-image"
                  class="floating-images floating-lg plane"
                  data-aos="zoom-in"
                  data-aos-delay="1500"
                  data-aos-duration="500"
                  data-aos-easing="linear"
                  data-aos-once="true"
                />
                <img
                  v-lazy="'img/theme/image-card.svg'"
                  alt="image-image"
                  class="floating-images floating-lg image"
                  data-aos="zoom-in"
                  data-aos-delay="1500"
                  data-aos-duration="500"
                  data-aos-easing="linear"
                  data-aos-once="true"
                />
                <img
                  v-lazy="'img/theme/code-card.svg'"
                  alt="code-image"
                  class="floating-images floating code"
                  data-aos="zoom-in"
                  data-aos-delay="1500"
                  data-aos-duration="500"
                  data-aos-easing="linear"
                  data-aos-once="true"
                />
                <img
                  v-lazy="'img/theme/astro.svg'"
                  alt="astro-image"
                  class="floating-images floating-sm astro"
                  data-aos="zoom-in"
                  data-aos-delay="1500"
                  data-aos-duration="500"
                  data-aos-easing="linear"
                  data-aos-once="true"
                />
                <img
                  v-lazy="'img/theme/stars.svg'"
                  alt="stars-image"
                  class="floating-images stars"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="linear"
                  data-aos-once="true"
                />
                <img
                  v-lazy="'img/theme/alien.svg'"
                  alt="alien-image"
                  class="floating-images floating-sm alien"
                  data-aos="zoom-in"
                  data-aos-delay="1500"
                  data-aos-duration="500"
                  data-aos-easing="linear"
                  data-aos-once="true"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>

    <section class="section section-lg negative-margin-top" id="about">
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-12 mt-lg">
            <div class="row justify-content-center align-items-center">
              <div class="col-md-6 mb-5 mb-lg-0 order-lg-2 order-md-2">
                <h2 class="display-3">
                  The perfect blend of
                  <br />creativity and technology.
                </h2>
                <p class="lead">
                  We are
                  <strong>NiftyStack.IO</strong>, a design and development company currently based in Japan. Consisting of individuals with experience and expertise in the IT industry for almost a decade, we aim to help businesses scale
                  and achieve their goals.
                </p>
                <p class="lead">
                  <strong>We integrate creativity with technology.</strong> Through this, and our significant amount of experience in IT, we find unique approaches to solving problems and building new products.
                </p>
                <br />
              </div>
              <div class="col-md-6 mb-5 mb-lg-0 text-center order-lg-1 order-md-1">
                <img
                  v-lazy="'img/brand/nifty-triangle.svg'"
                  alt="logo"
                  class="img-fluid"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="linear"
                  data-aos-once="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg bg-secondary" id="services">
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-12 order-lg-1">
            <h2 class="display-3 text-center">Our Services</h2>
            <div class="row mt-5">
              <div class="col-lg-4 mb-5 mb-lg-0">
                <card class="height-100">
                  <div>
                    <div class="text-center">
                      <!-- <img
                        v-lazy="'img/theme/service-one.svg'"
                        alt="service-image"
                        class="img-fluid service-image"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-easing="linear"
                        data-aos-once="true"
                      /> -->
                      <testCanvas
                        :canvas-id="'serviceOne'"
                        :loading-screen-id="'serviceLoading'"
                        :layer-data="serviceLayers"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-easing="linear"
                        data-aos-once="true"
                      />
                    </div>
                    <div>
                      <h5 class="title text-success text-center">Web and App Development</h5>
                      <br />
                      <p>Revamping or creating from scratch strategic web pages and apps. We write neat and well-structured code that's easy to maintain and scale.</p>
                    </div>
                  </div>
                </card>
              </div>
              <div class="col-lg-4 mb-5 mb-lg-0">
                <card class="height-100">
                  <div>
                    <div class="text-center">
                      <!-- <img
                        v-lazy="'img/theme/service-two.svg'"
                        alt="service-image"
                        class="img-fluid service-image"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-easing="linear"
                        data-aos-once="true"
                      /> -->
                      <testCanvas
                        :canvas-id="'serviceTwo'"
                        :loading-screen-id="'serviceLoadingTwo'"
                        :layer-data="serviceLayersTwo"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-easing="linear"
                        data-aos-once="true"
                      />
                    </div>
                    <div>
                      <h5 class="title text-success text-center">Machine Learning</h5>
                      <br />
                      <p>Data modeling APIs, machine learning algorithms, data transformations and predictive analytics.</p>
                    </div>
                  </div>
                </card>
              </div>
              <div class="col-lg-4 mb-5 mb-lg-0">
                <card class="height-100">
                  <div>
                    <div class="text-center">
                      <!-- <img
                        v-lazy="'img/theme/service-three.svg'"
                        alt="service-image"
                        class="img-fluid service-image"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-easing="linear"
                        data-aos-once="true"
                      /> -->
                      <testCanvas
                        :canvas-id="'serviceThree'"
                        :loading-screen-id="'serviceLoadingThree'"
                        :layer-data="serviceLayersThree"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-easing="linear"
                        data-aos-once="true"
                      />
                    </div>
                    <div>
                      <h5 class="title text-success text-center">UI Design</h5>
                      <br />
                      <p>Creating original and engaging interfaces for you and your users. Working in close cooperation with businesses to provide appealing and intuitive products.</p>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg" id="projects">
      <div class="container py-0">
        <div class="row row-grid align-items-center">
          <div class="col-lg-12 order-lg-1">
            <h2 class="display-3 text-center">Our Projects</h2>
            <div class="row row-grid align-items-center mt-5">
              <div class="col-md-6">
                <div class="card bg-default shadow border-0">
                  <img
                    v-lazy="'img/theme/palette-town.jpg'"
                    class="card-img-top"
                    alt="app-image"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-easing="linear"
                    data-aos-once="true"
                  />
                  <blockquote class="card-blockquote">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      class="svg-bg"
                    >
                      <polygon points="0,52 583,95 0,95" class="fill-default" />
                      <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default" />
                    </svg>
                    <h4 class="display-3 font-weight-bold text-white">Palette Town</h4>
                    <p
                      class="lead text-italic text-white"
                    >Palette Town is an app that lets you extract colors using your phone's camera. Make a collection of color palettes that you can use for your next project!</p>
                  </blockquote>
                </div>
              </div>
              <div class="col-md-6">
                <div class="pl-md-5">
                  <img
                    v-lazy="'img/projects/pallete-town.svg'"
                    alt="app-icon"
                    class="img-fluid"
                    style="max-width:150px; margin-left: -15px;"
                  />

                  <h3>Palette Town</h3>
                  <p class="lead">
                    Choosing the perfect color scheme is an important part of any website or app design.
                    <br />A unique color combination is a key way to express the personality of your brand.
                  </p>
                  <p
                    class="lead"
                  >Palette Town lets you generate awesome color palettes using your phone's camera or from an image. See endless color inspiration and possibilities!</p>
                  <p class="text-warning">Available for iOS and Android soon!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import testCanvas from "@/layout/testCanvas";
export default {
  name: "home",
  components: { testCanvas },
  data() {
    return {
      serviceLayers: [
        {
          image: null,
          src: "img/services/layer_1_1.png",
          z_index: -2.5,
          position: { x: 0, y: 0 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_3_1.png",
          z_index: -1.2,
          position: { x: 110, y: 70 },
          blend: "overlay",
          opacity: 0.3
        },
        {
          image: null,
          src: "img/services/mask.png",
          z_index: -1.5,
          position: { x: 0, y: 0 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_4_1.png",
          z_index: 1,
          position: { x: 145, y: 140 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_5_1.png",
          z_index: 0,
          position: { x: 120, y: 200 },
          blend: "darken",
          opacity: 0.5
        },
        {
          image: null,
          src: "img/services/layer_6_1.png",
          z_index: 1,
          position: { x: 125, y: 210 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_7_1.png",
          z_index: 2,
          position: { x: 250, y: 450 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_8_1.png",
          z_index: 3,
          position: { x: 580, y: 240 },
          blend: null,
          opacity: 1
        }
      ],
      serviceLayersTwo: [
        {
          image: null,
          src: "img/services/layer_1_1.png",
          z_index: -2.5,
          position: { x: 0, y: 0 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_3_1.png",
          z_index: -1.2,
          position: { x: 110, y: 70 },
          blend: null,
          opacity: 0.3
        },
        {
          image: null,
          src: "img/services/mask.png",
          z_index: -1.5,
          position: { x: 0, y: 0 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_4_1.png",
          z_index: 1,
          position: { x: 145, y: 140 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_5_2.png",
          z_index: 0,
          position: { x: 90, y: 200 },
          blend: "darken",
          opacity: 0.5
        },
        {
          image: null,
          src: "img/services/layer_6_2.png",
          z_index: 1,
          position: { x: 90, y: 200 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_7_2.png",
          z_index: 2,
          position: { x: 200, y: 450 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_8_2.png",
          z_index: 3,
          position: { x: 695, y: 390 },
          blend: null,
          opacity: 1
        }
      ],
      serviceLayersThree: [
        {
          image: null,
          src: "img/services/layer_1_1.png",
          z_index: -2.5,
          position: { x: 0, y: 0 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_3_1.png",
          z_index: -1.2,
          position: { x: 110, y: 70 },
          blend: null,
          opacity: 0.3
        },
        {
          image: null,
          src: "img/services/mask.png",
          z_index: -1.5,
          position: { x: 0, y: 0 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_4_1.png",
          z_index: 1,
          position: { x: 145, y: 140 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_5_3.png",
          z_index: 0,
          position: { x: 125, y: 275 },
          blend: "darken",
          opacity: 0.5
        },
        {
          image: null,
          src: "img/services/layer_6_3.png",
          z_index: 1,
          position: { x: 125, y: 275 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_7_3.png",
          z_index: 2,
          position: { x: 380, y: 500 },
          blend: null,
          opacity: 1
        },
        {
          image: null,
          src: "img/services/layer_8_3.png",
          z_index: 3,
          position: { x: 265, y: 480 },
          blend: null,
          opacity: 1
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.service-image {
  max-width: 300px;
}
</style>