<template>
  <footer class="footer has-cards" id="contact">
    <div class="container container-lg mt-5 mb-5">
      <img v-lazy="'img/theme/planet-1.svg'" alt="planets" style="max-width: 100px;" class="planet one" />
      <img v-lazy="'img/theme/planet-2.svg'" alt="planets" style="max-width: 100px;" class="planet two" />
      <img
        v-lazy="'img/theme/planet-3.svg'"
        alt="planets"
        style="max-width: 100px;"
        class="planet three"
      />
      <img v-lazy="'img/theme/planet-4.svg'" alt="planets" style="max-width: 100px;" class="planet four" />
      <img v-lazy="'img/theme/planet-5.svg'" alt="planets" style="max-width: 100px;" class="planet five" />
      <div class="row justify-content-center">
        <div class="col-lg-6 mt-5 contact-form">
          <div class="p-3">
            <h4 class="mb-1 text-white">Want to work with us?</h4>
            <p class="mt-0 text-white">
              If you have a project in mind or
              <br />would like to collaborate, send us a message!
            </p>
            <base-alert
              :type="alertType"
              :icon="alertIcon"
              dismissible
              :visible="alertVisible"
              @close-message="onCloseMessage"
            >
              <span slot="text">{{ alertMessage }}</span>
            </base-alert>
            <form v-on:submit.prevent="sendMessage(form)" novalidate>
              <div>
                <base-input
                  v-validate="'required'"
                  class="mt-5"
                  alternative
                  placeholder="Your name"
                  addon-left-icon="ni ni-user-run"
                  v-model="form.name"
                  type="text"
                  name="name"
                  :class="{'has-danger': errors.has('name') || responseErrors.name}"
                ></base-input>
                <div class="error-message">
                  <span
                    v-show="errors.has('name')"
                    class="help text-warning"
                  >{{ errors.first('name') }}</span>
                  <span
                    v-if="responseErrors.name"
                    class="help text-warning"
                  >{{ responseErrors.name }}</span>
                </div>
              </div>
              <div>
                <base-input
                  v-validate="'required|email'"
                  alternative
                  placeholder="Email address"
                  addon-left-icon="ni ni-email-83"
                  v-model="form.email"
                  type="email"
                  name="email"
                  :class="{'has-danger': errors.has('email') || responseErrors.email}"
                ></base-input>
                <div class="error-message">
                  <span
                    v-show="errors.has('email')"
                    class="help text-warning"
                  >{{ errors.first('email')}}</span>
                  <span
                    v-if="responseErrors.email"
                    class="help text-warning"
                  >{{ responseErrors.email }}</span>
                </div>
              </div>
              <div>
                <base-input class="mb-4">
                  <textarea
                    v-validate="'required'"
                    class="form-control form-control-alternative"
                    name="body"
                    rows="4"
                    cols="80"
                    placeholder="Type a message..."
                    v-model="form.body"
                    :class="{'has-danger': errors.has('body') || responseErrors.body}"
                  ></textarea>
                </base-input>
                <div class="error-message">
                  <span
                    v-show="errors.has('body')"
                    class="help text-warning"
                  >{{ errors.first('body') }}</span>
                  <span
                    v-if="responseErrors.body"
                    class="help text-warning"
                  >{{ responseErrors.body }}</span>
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-info btn-lg btn-block mt-3"
                name="message"
                v-bind:disabled="alertVisible"
              >Send Message</button>
            </form>
          </div>
        </div>
        <div class="col-lg-6 mt-5">
          <div class="row justify-content-center align-content-center height-100">
            <gmap-map :center="center" :zoom="4" map-type-id="terrain" v-bind:options="mapStyle">
              <gmap-custom-marker :marker="marker" alignment="top">
                <div class="map-marker">
                  <div class="map-bubble">
                    <h4>NiftyStack.IO</h4>
                    <span>
                      Toda-shi, 257-1 Niizo
                      <br />Saitama, Japan
                    </span>
                  </div>
                  <i class="ni ni-spaceship map-pin text-warning"></i>
                </div>
              </gmap-custom-marker>
            </gmap-map>
            <div class="pulsating-circle"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-5 mb-5">
      <div class="copyright text-center text-white">
        &copy; {{year}}
        NiftyStack.IO
      </div>
    </div>
  </footer>
</template>
<script>
import GmapCustomMarker from "vue2-gmap-custom-marker";

export default {
  name: "app-footer",
  components: {
    "gmap-custom-marker": GmapCustomMarker
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        body: ""
      },
      alertType: "",
      alertMessage: "",
      alertVisible: false,
      alertIcon: "",
      responseErrors: [],
      year: new Date().getFullYear(),
      center: { lat: 40.8176, lng: 139.6779 },
      marker: { lat: 35.8176, lng: 139.6779 },
      mapStyle: {
        disableDefaultUI: true,
        styles: [
          {
            featureType: "all",
            elementType: "all",
            stylers: [
              {
                saturation: "32"
              },
              {
                lightness: "-3"
              },
              {
                visibility: "on"
              },
              {
                weight: "1.18"
              }
            ]
          },
          {
            featureType: "administrative",
            elementType: "labels",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "landscape",
            elementType: "labels",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "landscape.man_made",
            elementType: "all",
            stylers: [
              {
                saturation: "-70"
              },
              {
                lightness: "14"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            elementType: "labels",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                saturation: "100"
              },
              {
                lightness: "-14"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "labels",
            stylers: [
              {
                visibility: "off"
              },
              {
                lightness: "12"
              }
            ]
          }
        ]
      }
    };
  },
  methods: {
    sendMessage: function(form) {
      this.$validator.validateAll().then(result => {
        if (result) {
          // POST /someUrl
          this.$http
            .post("https://msgx.nftystck.com/v1/messages", {
              name: form.name,
              email: form.email,
              body: form.body
            })
            .then(result => {
              this.alertType = "success";
              this.alertMessage = "Thank you! Your message was sent.";
              this.alertIcon = "ni ni-like-2";
              this.alertVisible = true;
              this.responseErrors = {};
            })
            .catch(err => {
              var errors = err.data.errors;
              var tempErrors = {};
              for (let key of Object.keys(errors)) {
                var msgs = "";
                for (let msg of errors[key]) {
                  msgs += msg;
                }
                tempErrors[key] = msgs;
              }
              this.responseErrors = tempErrors;
            });
        }
      });
    },
    onCloseMessage: function(event, value) {
      this.alertVisible = value;
    }
  }
};
</script>
<style lang="scss" scoped>
.footer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vue-map-container {
  border-radius: 500px;
  width: 500px;
  height: 500px;
  overflow: hidden;
  position: relative;
  z-index: 100;
}

.contact-form {
  position: relative;
  z-index: 10;
}

.planet {
  position: absolute;
  width: 100%;

  &.one {
    left: -8%;
  }

  &.two {
    right: 0;
  }

  &.three {
    bottom: -5%;
    left: -5%;
  }

  &.four {
    bottom: 0;
    right: 0;
  }

  &.five {
    left: 48%;
    top: 50%;
  }
}

.pulsating-circle {
  transform: skew(0deg);
  width: 700px;
  height: 700px;
  top: 50%;
  left: 50%;
  margin-top: -350px;
  margin-left: -350px;
  z-index: 5;
}

.map-marker {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.map-bubble {
  padding: 10px;
  background: #fff;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 3px;
  position: relative;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7.5px 0 7.5px;
    border-color: #ffffff transparent transparent transparent;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -7.5px;
  }
}

.map-pin {
  font-size: 30px;
}

.form-group {
  margin-bottom: 0px !important;
}
.error-message {
  height: 25px;
  font-size: 12px;
  display: block;
  margin-top: 5px;
}
</style>
